@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700,700i&subset=greek-ext');
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Pacifico&display=swap');
* {
  box-sizing: border-box;
}
header {
  background: #ffffff17;
  backdrop-filter: blur(5px);
  width: 100%;
  padding: 10px 0px;
}
header .logo {
  margin: 0;
  text-align: center;
}
body {
  height: 100vh;
  background-image: url(./images/bg.jpg) ;
  background-position: center center;
  background-size: cover;
  color: whitesmoke;
  font-family: 'Noto Sans', sans-serif;
  
}
.logo {
  font-family: "Pacifico", cursive;
  font-weight: 400;
  font-style: normal;
  text-align: center;
}
footer {
  position: absolute;
  bottom: 0;
}
.eye-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: whitesmoke;
}
.button {
  background-color: initial;
  background-image: linear-gradient(#f88000 0, #f6ea00 100%);
  border-radius: 5px;
  border-style: none;
  box-shadow: rgba(245, 244, 247, .25) 0 1px 1px inset;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 500;
  height: 40px;
  line-height: 30px;
  margin-left: -4px;
  outline: 0;
  text-align: center;
  transition: all .3s cubic-bezier(.05, .03, .35, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: bottom;
  width: 140px;
}

.button:hover {
  opacity: .7;
}
.cvv {
  margin-left: 10px;
  width: 100%;
  max-width: 160px;
}


.button_cancel {
  background-color: initial;
  background-image: linear-gradient(#f60509 0, #8a1c06bb 100%);
  border-radius: 5px;
  border-style: none;
  box-shadow: rgba(245, 244, 247, .25) 0 1px 1px inset;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 500;
  height: 40px;
  line-height: 30px;
  margin-left: -4px;
  outline: 0;
  text-align: center;
  transition: all .3s cubic-bezier(.05, .03, .35, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: bottom;
  width: 140px;
}

.button_cancel:hover {
  opacity: .7;
}


.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
.wrapper_step1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 40px;
  backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.131);
  border-radius: 25px;
  width: fit-content;
  margin: 60px auto 0;

  button {
    max-width: 200px;
    width: 100%;
  }
  img {
    max-width: 250px;
    width: 100%;
  }
}
.wrapper_step3 {
  max-width: 500px;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 0 auto;
}
.input_wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;
}
.input_wrapper2 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

}
.input_wrapper2  div {
  max-width: 45%;
}
.input_wrapper label {
  display: flex;
  margin-bottom: 5px;
}
input {
  color: whitesmoke;
  background: transparent;
  padding: 10px;
  font-size: 18px;
  width: 100%;
  letter-spacing: .2rem;
  border-radius: 5px;
}
.w100 {
  width: 100%;
}
.errors {
  color: #f60509;
  position: absolute;
  bottom: -16px;
  font-size: 9px;
}
.button_wrapper {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
.input_wrapper3 {
  display: flex;
  align-items: center;
}
.amout {
  position: relative;

}
.amount select {
  position: absolute;
  right: 0;
  top: 27px;
  height: 44px;
  background: transparent;
  border: none;
  color: white;

}
option {
  color: black;
}
.text-center {
  text-align: center;
  text-transform: uppercase;
}
form {
  padding: 30px 40px;
  backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.131);
  border-radius: 25px;
  width: fit-content;
  margin: 0 auto;

}
h1 {
  cursor: pointer;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

input[type=number] {
    -ms-appearance: textfield;
}

.success-container h1 {
  text-transform: capitalize;
  margin-top: 10%;
}

@media screen and (max-width: 1000px) {
  .button_wrapper {
    justify-content: space-between;
  }
  .button_wrapper button {
    max-width: 47%;
  }
}

@media screen and (max-width: 800px) {
  form {
    padding: 30px 10px;
  }
  .input_wrapper2 div {
    width: 130px;
    max-width: inherit;
  }
  .success-container h1 {
    padding: 5px 20px;
    margin-top: 50%;
  }
}

.success-text {
  text-align: center;
  max-width: 500px;
  margin: 20px auto;
}
